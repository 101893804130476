<template>
  <div>
    <div class="title">{{ $t("baseInfo") }}</div>
    <el-form ref="form" :model="form" :label-width="language === 'zh-cn' ? '200px' : '230px'">
      <el-form-item :label="$t('investorType')" style="width: 100%">
        <span>
          {{
          form.investorType === symbol.ORGANIZATION.value
          ? $t("investorTypeRadio.institutionalInvestor")
          : $t("investorTypeRadio.individualInvestor")
          }}
        </span>
      </el-form-item>
      <el-form-item :label="$t('investorName')" style="width: 100%">
        <span>{{ form.investorName }}</span>
      </el-form-item>
      <el-form-item :label="$t('investorEMail')" style="width: 100%">
        <span>{{ form.investorEmail }}</span>
      </el-form-item>
      <el-form-item :label="$t('investorMobilePhoneNumber')" style="width: 100%">
        <span>{{ form.investorMobileNumber }}</span>
      </el-form-item>
      <el-form-item :label="$t('idCardOrCompanyCode')" style="width: 100%">
        <span>{{ form.cardId }}</span>
      </el-form-item>
      <el-form-item :label="$t('supportingDocumentationFile')" style="width: 100%">
        <el-link
          class="link-style"
          v-for="(item,index) in fileData"
          :key="index"
          :underline="false"
          type="primary"
          @click="goLink(item)"
        >{{ item.split("/")[item.split("/").length - 1] }}</el-link>
      </el-form-item>
    </el-form>
    <div class="title">{{ $t("bankInformation") }}</div>
    <el-form ref="form" :model="form" style="width: 1100px" label-width="200px">
        <div>
            <el-form-item :label="$t('bankOfCityCountry')" label-width="200px">
                <span v-if="form.areaId">{{ getAreaName(form.areaId) }}</span>
                <span v-else></span>
            </el-form-item>
        </div>
        <el-form-item :label="$t('bankOfDeposit')">
            <div class="flex-row">
                <el-image v-if="form.receivingBankIcon" :src="form.receivingBankIcon" style="width: 18px; height: 18px; margin-right: 10px;"></el-image>
                <span v-if="language==='zh-cn'">{{ form.receivingBank }}</span>
                <span v-else>{{ form.receivingBankEn || form.receivingBank }}</span>
            </div>
        </el-form-item>
        <el-form-item :label="$t('companyCreate.swiftCode')" label-width="200px">
            <span>{{ form.swiftCode }}</span>
        </el-form-item>
      <el-form-item :label="$t('companyCreate.bankNumber')">
        <span>{{ form.bankAccountNumber }}</span>
      </el-form-item>
      <el-form-item :label="$t('companyCreate.bankAccount')" label-width="200px">
        <span>{{ form.accountHolderName }}</span>
      </el-form-item>
    </el-form>
    <div class="title">{{ $t("investmentInformation") }}</div>
    <el-form
      ref="form"
      :model="form"
      style="width: 1100px"
      :label-width="language === 'zh-cn' ? '200px' : '300px'"
    >
      <el-form-item :label="$t('targetTotalInvestmentSize')" style="width: 100%">
        <span>{{ (Number(form.totalInvestmentSize) / $enums.UNIT_MILLION).toFixed(2) || 0 }}&nbsp;&nbsp;{{ $t("millionDollars") }}</span>
      </el-form-item>
      <el-form-item :label="$t('riskTolerance')" style="width: 100%">
        <span>
          {{
          form.riskTolerance === risk.KEEP.value
          ? $t("riskToleranceRadio.conservative")
          : form.riskTolerance === risk.PRUDENT.value
          ? $t("riskToleranceRadio.cautious")
          : form.riskTolerance === risk.STEADY.value
          ? $t("riskToleranceRadio.steady")
          : form.riskTolerance === risk.ACTIVE.value
          ? $t("riskToleranceRadio.positive")
          : $t("riskToleranceRadio.radical")
          }}
        </span>
      </el-form-item>
      <el-form-item :label="$t('experienceInSupplyChainInvestment')" style="width: 100%">
        <span>
          {{
          form.invertmentYears === experience.NONE.value
          ? $t("experienceInSupplyChainInvestmentRadio.none")
          : form.invertmentYears === experience.ONE_TO_THREE.value
          ? $t("experienceInSupplyChainInvestmentRadio.otYears")
          : form.invertmentYears === experience.THREE_TO_FIVE.value
          ? $t("experienceInSupplyChainInvestmentRadio.tfYears")
          : form.invertmentYears === experience.FIVE_TO_TEN.value
          ? $t("experienceInSupplyChainInvestmentRadio.ftYears")
          : $t("experienceInSupplyChainInvestmentRadio.aboveYears")
          }}
        </span>
      </el-form-item>
    </el-form>
    <div style="text-align: center; margin-top: 30px">
      <el-button class="btn-black" v-on:click="close">{{ $t("btnI18n.Close") }}</el-button>
    </div>
  </div>
</template>

<script>
import {getObjByKeyValue} from "@/utils";
export default {
  name: "invest-detail",
  data() {
    return {
      symbol: this.$enums.INVESTORTYPE,
      risk: this.$enums.RISKTOLERANCE,
      experience: this.$enums.EXPERIENCEINVESTMENT,
      auth: this.$route.query.auth,
      authAll: this.$enums.AUTH,
      form: {
        name: '',
        rate: 95,
        method: this.$t('equalPrincipal'),
        payReceived: '2020-10-21',
        day: 60,
        person: this.$t('zhangSan'),
        phone: '123',
        endDate: '2020-10-21',
        repayment: '2020-10-21',
        desc: this.$t('operationDemand'),
        account: '6214 8301 5979 4133',
        user: this.$t('zeroSeven'),
        bank: this.$t('merchantsBank'),
      },
      fileData: [],
      address: '',
        // bank update
        optionsCountry: [],
        // bank update
    };
  },
  mounted() {
      this.getAreaList();
    this.initData();
  },
  methods: {
      // bank update
      getAreaList() {
          let _this = this;
          this.optionsCountry = [];
          this.$axios.get("/v1/bank/area").then(res => {
              if (!res.code) {
                  _this.optionsCountry = res.data;
              }
          });
      },
      getAreaName(value) {
          if (!value) return "";
          if (!this.optionsCountry.length) return "";
          if (this.language === "zh-cn") {
              return getObjByKeyValue(this.optionsCountry, "code", Number(value)).cnName;
          } else {
              return getObjByKeyValue(this.optionsCountry, "code", Number(value)).enName;
          }
      },
      // bank update
    goLink(url) {
      if (/.*(\.png|\.jpg|\.jpeg|\.gif|\.PNG|\.JPG|\.JPEG|\.GIF)$/.test(url)) {
        const image = new Image();
        image.src = url;
        const imgWindow = window.open(url);
        imgWindow.document.write(image.outerHTML);
      } else if (/.*(\.pdf|\.PDF)$/.test(url)) {
          let routeData = this.$router.resolve({
              query: {url:url},
              path:'/reviewPDF'
          });
          window.open(routeData.href, '_blank');
      } else {
          window.open(url);
      }
    },
    initData() {
      this.$axios.get("/v1/invest/detail").then(result => {
        if (result.code === 0 || result.code === '0') {
          this.form = result.data;
          if (this.form.supportingDocument.indexOf('[') === -1) {
            this.fileData.push(this.form.supportingDocument);
          } else {
            this.fileData = JSON.parse(this.form.supportingDocument);
          }
        } else {
          this.$message({ type: 'error', message: result.message });
        }
      }).catch(error => console.log(error));
    },
    close() {
      this.$router.push({ path: "/invest-all" });
    }
  },
  computed: {
    language() {
      return this.$store.getters['auth/language'];
    }
  },
  watch: {
    language() {
      console.log(this.language);
    }
  }
};
</script>

<style lang="scss" scoped>
.el-form-item {
  width: 550px;
  display: inline-block;
  margin-bottom: 0;
}
.link-style {
  display: block;
}
</style>
